import React from "react";
import { Link } from "react-router-dom";
const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const ProjectAreaOne = () => {
  return (
    <div
      className="project-section pd-top-40 "
      style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center">
              {/* <h6 className="sub-title">WHAT WE OFFER</h6> */}
              <h2 className="title">
                Discover Customized <span> IT Solutions </span> and other{" "}
                <span> Services </span>
              </h2>
            </div>
          </div>
        </div>

        {/* <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-tabs1"
            role="tabpanel"
            aria-labelledby="nav-tabs1-tab"
          >
            <div className="row justify-content-center">
               <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered">Managed IT Services</h2>
                  <img
                    src="assets/img/gallery/managed-it-service.jpg"
                    alt="Managed IT Service"
                  />
                  <div className="overlay"></div> 
                  <div className="details">
                    
                    <p>
                      Elevate your business efficiency with our comprehensive
                      managed IT solutions.
                    </p>

                    <Link className="read-more-text" to="/managed-it-services" onClick={scrollToTop}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
               <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered">Revenue Cycle Management</h2>
                  <img
                    src="assets/img/gallery/revenue-cycle-management.jpg"
                    alt="Revenue Cycle Management"
                  />
                  <div className="overlay"></div>  
                  <div className="details">
                     
                    <p>
                      Maximize revenue streams and streamline financial
                      processes with our tailored management services.
                    </p>
                    <Link
                      className="read-more-text"
                      to="/revenue-cycle-management"
                      onClick={scrollToTop}
                    >
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
               <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered">Healthcare Staff Augmentation</h2>
                  <img
                    src="assets/img/gallery/healthcare-staff-augmentation.jpg"
                    alt="Healthcare Staff Augmentation"
                  />
                  <div className="overlay"></div>  
                  <div className="details">
                     
                    <p>
                      Meet fluctuating demands with ease through our healthcare
                      staff augmentation services.
                    </p>

                    <Link
                      className="read-more-text"
                      to="/healthcare-staff-augmentation"
                      onClick={scrollToTop}
                    >
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
               
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered-2">Electronic Health Record</h2>
                  <img
                    src="assets/img/gallery/ehr.jpg"
                    alt="Electronic Health Record"
                  />
                  <div className="overlay"></div>  
                  <div className="details">
                     
                    <p>
                      Embrace the future of healthcare with our advanced EHR
                      solutions.
                    </p>
                    <Link className="read-more-text" to="/electronic-health-record"
                      onClick={scrollToTop}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered-3">Enterprise IT</h2>
                  <img
                    src="assets/img/gallery/enterprise-it.jpg"
                    alt="Enterprise IT"
                  />
                  <div className="overlay"></div> 
                  <div className="details">
                    
                    <p>
                      Scale your operations efficiently with our enterprise IT
                      services.
                    </p>
                    <Link className="read-more-text" to="/enterprise-it" onClick={scrollToTop}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered-3">Marketing</h2>
                  <img src="assets/img/gallery/marketing.jpg" alt="Marketing" />
                  <div className="overlay"></div> 
                  <div className="details">
                   
                    <p>
                      Amplify your brand presence and reach your target audience
                      effectively with our strategic marketing services
                    </p>
                    <Link className="read-more-text" to="/marketing" onClick={scrollToTop}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div> */}
        <div className="project_area_container">
          <div className="project_items">
            <div className="project_item_img">
              <img
                src="assets/img/gallery/project/managed-it-service.jpg"
                alt="Managed IT Service"
              />
            </div>
            <div className="project_item_content">
              <h2>Managed IT Service</h2>
              <p>
                Elevate your business efficiency with our comprehensive
                managed IT solutions.
              </p>

              <Link to="/managed-it-services" className="btn btn-border-base" onClick={scrollToTop}>
                Read More
              </Link>
            </div>

          </div>
          <div className="project_items">
            <div className="project_item_img">
              <img
                src="assets/img/gallery/project/revenue-cycle-management.jpg"
                alt="Revenue Cycle Management"
              />
            </div>
            <div className="project_item_content">
              <h2>Revenue Cycle Management</h2>
              <p>
                Maximize revenue streams and streamline financial
                processes with our tailored  services.
              </p>
              <Link
                className="btn btn-border-base"
                to="/revenue-cycle-management"
                onClick={scrollToTop}
              >
                Read More
              </Link>
            </div>

          </div>
          <div className="project_items">
            <div className="project_item_img">
              <img
                src="assets/img/gallery/project/healthcare-staff-augmentation.jpg"
                alt="Healthcare Staff Augmentation"
              />
            </div>
            <div className="project_item_content">

              <h2>Healthcare Staff Augmentation</h2>
              <p>
                Meet fluctuating demands with ease through our healthcare
                staff augmentation services.
              </p>

              <Link
                className="btn btn-border-base"
                to="/healthcare-staff-augmentation"
                onClick={scrollToTop}
              >
                Read More
              </Link>
            </div>

          </div>

          <div className="project_items">
            <div className="project_item_img">
              <img
                src="assets/img/gallery/project/ehr.jpg"
                alt="Electronic Health Record"
              />
            </div>
            <div className="project_item_content">
              <h2>Electronic Health Record</h2>
              <p>
                Embrace the future of healthcare with our advanced EHR
                solutions.
              </p>
              <Link to="/electronic-health-record"
                className="btn btn-border-base"
                onClick={scrollToTop}>
                Read More
              </Link>
            </div>

          </div>

          <div className="project_items">
            <div className="project_item_img">
              <img
                src="assets/img/gallery/project/enterprise-it.jpg"
                alt="Enterprise IT"
              />
            </div>
            <div className="project_item_content">
              <h2>Enterprise IT</h2>
              <p>
                Scale your operations efficiently with our enterprise IT
                services.
              </p>
              <Link to="/enterprise-it" className="btn btn-border-base" onClick={scrollToTop}>
                Read More
              </Link>
            </div>

          </div>

          <div className="project_items">
            <div className="project_item_img">
              <img src="assets/img/gallery/project/marketing.jpg" alt="Marketing" />
            </div>
            <div className="project_item_content">
              <h2>Marketing</h2>
              <p>
                Amplify your brand presence, reach your audience
                effectively with our  marketing services
              </p>
              <Link to="/marketing" className="btn btn-border-base" onClick={scrollToTop}>
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaOne;
