import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import blogBanner from "./Images/category-page-blog-wp.webp";
import NavbarTwo from "../Header/NavbarTwo";
import FooterOne from "../Footer/FooterTwo";
import "./style/allcategoriespage.css"; // Include a CSS file for styles

const client = new ApolloClient({
    uri: "https://techmatterglobal.com/blog/graphql",
    cache: new InMemoryCache(),
});

const AllCategoriesPage = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        setLoading(true);
        client
            .query({
                query: gql`
                    query GetAllCategories {
                        categories(where: { hideEmpty: false }, first: 50) {
                            nodes {
                                id
                                name
                                slug
                                description
                            }
                        }
                    }
                `,
            })
            .then((result) => {
                // Filter out "Uncategorized" and "All" categories
                const filteredCategories = result.data.categories.nodes.filter(
                    (category) =>
                        category.name !== "Uncategorized" &&
                        category.name !== "All"
                );
                setCategories(filteredCategories);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
                setError("Failed to fetch categories.");
                setLoading(false);
            });
    }, []);

    if (error) return <p>{error}</p>;

    return (
        <div>
            <NavbarTwo />

            <div
                className="breadcrumb-area bg-cover"
                style={{ backgroundImage: `url(${blogBanner})` }}
            >
                <div className="container">
                    <div className="breadcrumb-inner">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <h1 className="page-title">All Categories</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pd-top-90 pd-bottom-90">
                <h2 className="section-title">Explore Our Categories</h2>

                {/* Show loading only in the categories grid */}
                {loading ? (
                    <div className="section-loading">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <div className="categories-grid">
                        {categories.map((category) => (
                            <div className="category-card" key={category.id}>
                                <h3 className="category-title">{category.name}</h3>
                                <p className="category-description">
                                    {category.description || "No description available."}
                                </p>
                                <Link className="btn btn-border-base" to={`/blogs/category/${category.slug}`} onClick={scrollToTop}>
                                    View Posts

                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <FooterOne />
        </div>
    );
};

export default AllCategoriesPage;
